<template>
  <!--公司理念-->
    <div class="yqlncontent divMa">

      <titleDiv :title="notionMainName" english-title="COMPANY CONCEPT"></titleDiv>

      <div class="yqlntext wow animate__animated animate__bounceIn"
           v-html="notionMainContent">
      </div>
      <div class="yqlnbox wow animate__animated animate__fadeInUp">
        <div class="yqln_item" v-for="(item, index) in list" :key="index">
          <img class="yqln_item_img" :src="staticurlSrc+item.notionLogo" />
          <div class="yqln_item_cont">
            <div class="yqln_item_title">{{ item.notionName }}</div>
            <div class="yqln_item_text" v-html="item.notionContent"></div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import '@/style/css/index.css';
import {staticurl} from "@/utils/baseurl";
import {yqlnapi} from "@/api/zjyqapi";
import titleDiv from "@/components/title.vue";

export default {
  components:{
    titleDiv
  },
  name: "gsln",
  data() {
    return {
      project:this.$store.state.project,
      staticurlSrc:staticurl,
      yqln: {},
      list:[],
      notionMainName:'',
      notionMainContent:'',
    }
  },
  props:["titleEnglish","bjImgHidden"],
  beforeMount(){
    let key='gsln';
    console.log(key)
    //let projeSid = this.$store.state.project.projectid;//项目id
    //sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    if(!(this.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)

      yqlnapi({ apartmentSid: this.project.projectid }).then((res) => {
        this.yqln=res;
        this.notionMainName=this.yqln.tag.notionMainName;
        this.notionMainContent=this.yqln.tag.notionMainContent;
        this.list=this.yqln.data.result;
        window.sessionStorage.setItem(key, JSON.stringify(this.yqln))
      });

    }else {
      console.log("读缓存")
      this.yqln = JSON.parse(window.sessionStorage.getItem(key));
      this.notionMainName=this.yqln.tag.notionMainName;
      this.notionMainContent=this.yqln.tag.notionMainContent;
      this.list=this.yqln.data.result;
    }
  },
}
</script>

<style scoped>

.yqlncontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yqlntext {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1px;
  text-indent: 30px;
  /*margin-top: 40px;*/
  /*margin-bottom: 100px;*/
}

.yqlnbox {
  display: flex;
  flex-wrap: wrap;
}

.yqln_item {
  display: flex;
  margin: 50px 0;
}

.yqln_item_img {
  width: 60px;
  height: 60px;
  align-self: center;
  margin: 0 47px;
}

.yqln_item_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yqln_item_title {
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.yqln_item_text {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
}
@media (max-width: 600px){
  .yqlntext{
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
  }
  .yqlncontent{
    /*width: 100%;*/
    /*margin: 0 auto;*/
  }
  .yqlncontent>>> p{
    display: flex;
    flex-direction: column;
  }
  .yqln_item{
    margin: 10px 0;
  }
  .yqlntext >>> span{
    line-height: 9vw;
  }
}
</style>
